<template>
  <div v-click-outside="handleOutsideClick">
    <div
      class="user-dropdown d-flex align-items-center ml-3"
      @click="handleClick"
    >
      <div>
        <b-avatar variant="primary" :text="initials" class="mr-2"></b-avatar>
      </div>
      <div class="d-none d-sm-block ms-3">
        <span class="first-name">{{ firstName }}</span>
      </div>
      <div class="d-none d-md-block ms-md-2">
        <span
          :class="[open ? 'jbsmd-chevron-top' : 'jbsmd-chevron-down']"
          class="font-sm"
        ></span>
      </div>
    </div>
    <div
      :class="[open ? 'show' : '']"
      class="dropdown-menu dropdown-menu-right"
    >
      <div class="dropdown-header">
        <span class="d-block text-sm text-muted mb-1"
          ><translate>Signed in as</translate></span
        >
        <span class="d-block text-heading font-semibold">{{ fullName }}</span>
      </div>
      <div class="dropdown-divider"></div>
      <router-link
        v-if="!isOrphan"
        :to="{ name: 'account' }"
        class="dropdown-item"
        @click.native="closeDropdown"
      >
        <i class="jbsmd-user mr-1"></i> <translate>My profile</translate>
      </router-link>
      <router-link
        v-if="!isOrphan"
        :to="{ name: 'teams' }"
        class="dropdown-item"
        @click.native="closeDropdown"
      >
        <i class="jbsmd-users mr-1"></i> <translate>My teams</translate>
      </router-link>
      <a
        class="dropdown-item"
        href="javascript:;"
        @click.prevent="handleLogout"
      >
        <i class="jbsmd-logout mr-1"></i> <translate>Log Out</translate>
      </a>
    </div>
  </div>
</template>
<script>
import { BAvatar } from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import { rebootIntercom } from "@/utils/intercom";
import vClickOutside from "v-click-outside";
import Vue from "vue";

Vue.use(vClickOutside);

export default {
  components: { BAvatar },
  data() {
    return {
      open: false
    };
  },
  computed: {
    ...mapGetters("me", {
      currentUser: "user",
      lang: "lg",
      isOrphan: "isOrphan"
    }),
    fullName() {
      return this.currentUser?.full_name;
    },
    firstName() {
      return this.currentUser?.first_name;
    },
    lastName() {
      return this.currentUser?.last_name;
    },
    initials() {
      return this.firstName && this.lastName
        ? this.firstName[0] + this.lastName[0]
        : "";
    }
  },
  methods: {
    ...mapActions("me", ["logout"]),
    closeDropdown() {
      this.open = false;
    },
    handleLogout() {
      this.logout().finally(() => {
        rebootIntercom(null, this.lang);
        this.$router.push({ name: "login" });
      });
    },
    handleClick() {
      this.open = !this.open;
    },
    handleOutsideClick() {
      this.closeDropdown();
    }
  }
};
</script>
