<template>
  <div class="sidebar">
    <div class="sidebar-wrapper">
      <TeamSwitcher />
      <div class="cta-container mt-3" v-if="canManageOffers">
        <router-link
          to="/create-offer"
          class="btn btn-primary btn-block"
          exact-active-class=""
        >
          <i class="fa fa-plus mr-1"></i>
          {{ $gettext("Create a job") }}
        </router-link>
      </div>
      <ul class="nav">
        <li
          class="nav-item"
          v-for="(item, index) in filterEnabledItems(items)"
          :key="index + 100"
        >
          <a
            v-if="menuIsExpandable(item)"
            data-toggle="collapse"
            :aria-expanded="menuIsOpen(item)"
            @click.prevent="toggleExpandableMenu(item)"
          >
            <i :class="`jbsmd-${item.icon}`"></i>
            <p>{{ item.label }} <b class="caret"></b></p>
          </a>
          <div
            v-if="menuIsExpandable(item)"
            :class="[menuIsOpen(item) ? 'show' : '']"
            class="collapse"
          >
            <ul class="nav">
              <li
                v-for="(child, index) in filterEnabledItems(item.children)"
                :key="index"
              >
                <router-link active-class="active" :to="{ name: child.to }">
                  <span class="sidebar-mini-icon"
                    ><i :class="`jbsmd-${child.icon}`"></i
                  ></span>
                  <span class="sidebar-normal">{{ child.label }}</span>
                </router-link>
              </li>
            </ul>
          </div>

          <router-link
            v-else
            active-class="active"
            :to="{ name: item.to }"
            class="nav-link pmd-ripple-effect"
          >
            <i :class="`jbsmd-${item.icon}`"></i>
            <p>{{ item.label }}</p>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TeamSwitcher from "@/layouts/components/TeamSwitcher";

export default {
  components: { TeamSwitcher },
  data() {
    return {
      currentOpenedMenu: null
    };
  },
  created() {
    // active menu is a child of an expandable menu
    const openedMenu = this.items
      .filter(item => this.menuIsExpandable(item))
      .find(item =>
        item.children.find(child => child.to === this.$router.currentRoute.name)
      );

    if (openedMenu) {
      this.toggleExpandableMenu(openedMenu);
    }
  },
  computed: {
    ...mapGetters("me", [
      "user",
      "permissions",
      "canManageUsers",
      "canManageOffers",
      "canManageCompanies",
      "canManageCandidates",
      "canManageSubscriptions",
      "canManageCreditCard"
    ]),
    items() {
      return [
        {
          label: this.$gettext("Dashboard"),
          icon: "dashboard",
          to: "dashboard",
          enable: true
        },
        {
          label: this.$gettext("Job Offers"),
          icon: "job",
          to: "offers",
          enable: this.canManageOffers
        },
        {
          label: this.$gettext("Candidates"),
          icon: "users",
          to: "candidates",
          enable: this.canManageCandidates
        },
        {
          label: this.$gettext("Applications"),
          icon: "multiple-files",
          to: "applications",
          enable: this.canManageCandidates
        },
        {
          label: this.$gettext("Companies"),
          icon: "building",
          to: "companies",
          enable: this.canManageCompanies
        },
        {
          label: this.$gettext("Store"),
          icon: "shop",
          to: "boutique",
          enable: this.canManageSubscriptions
        },
        {
          id: "settings",
          label: this.$gettext("Team settings"),
          icon: "settings",
          enable: this.canManageUsers || this.canManageSubscriptions,
          children: [
            {
              label: this.$gettext("Users"),
              icon: "member",
              to: "users",
              enable: this.canManageUsers
            },
            {
              label: this.$gettext("Subscription"),
              icon: "crown",
              to: "subscription",
              enable: this.canManageSubscriptions
            },
            {
              label: this.$gettext("Credit cards"),
              icon: "wallet",
              to: "credit-cards",
              enable: this.canManageCreditCard
            },
            {
              label: this.$gettext("Billing history"),
              icon: "coins",
              to: "billing-history",
              enable: this.canManageSubscriptions
            }
          ]
        }
      ];
    }
  },
  methods: {
    filterEnabledItems(items) {
      return items.filter(item => item.enable);
    },
    toggleExpandableMenu(item) {
      this.currentOpenedMenu =
        this.currentOpenedMenu === item.id ? null : item.id;
    },
    menuIsExpandable(item) {
      return item.children !== undefined;
    },
    menuIsOpen(item) {
      return this.currentOpenedMenu === item.id;
    }
  }
};
</script>
