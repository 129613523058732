<template>
  <div class="team-switcher" v-if="!isOrphan">
    <div
      :class="[canSwitchTeam ? 'has-options' : '']"
      class="current-team"
      @click="handleClickCurrentTeam"
    >
      <span>
        <translate>Team</translate>
      </span>
      <div class="d-flex align-items-center justify-content-between">
        <div>{{ currentTeamName }}</div>
        <span
          v-if="canSwitchTeam"
          :class="[open ? 'jbsmd-chevron-top' : 'jbsmd-chevron-down']"
          class="font-sm"
        ></span>
      </div>
    </div>
    <div class="team-options" v-if="showOptions">
      <div
        class="option"
        v-for="(team, index) in otherTeams"
        :key="index"
        @click="handleTeamClick(team)"
      >
        {{ team.name }}
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      open: false
    };
  },
  computed: {
    ...mapGetters("me", ["user", "isOrphan"]),
    currentTeamName() {
      return (
        this.user.teams?.find(team => team.id === this.user.team_id)?.name || ""
      );
    },
    otherTeams() {
      return this.user.teams?.filter(team => team.id !== this.user.team_id);
    },
    canSwitchTeam() {
      return this.otherTeams?.length > 0;
    },
    showOptions() {
      return this.canSwitchTeam && this.open;
    }
  },
  methods: {
    ...mapActions("me", ["switchTeam"]),
    handleClickCurrentTeam() {
      this.open = !this.open;
    },
    handleTeamClick(team) {
      this.switchTeam(team.id).then(() => this.$router.go(0));
    }
  }
};
</script>
