<template>
  <form>
    <div class="input-group no-border position-relative">
      <b-form-input
        id="search"
        v-model="search"
        type="text"
        value=""
        class="form-control"
        autocomplete="off"
        :placeholder="$gettext('Search')"
        debounce="300"
        @update="submitSearch"
        @click.prevent="handleInputClick"
      />
      <div class="input-group-append">
        <div class="input-group-text">
          <BSpinner small v-if="isFetching" />
          <i v-else class="nc-icon nc-zoom-split"></i>
        </div>
      </div>
      <div
        class="search-content-wrapper"
        v-if="show"
        v-click-outside="handleOutsideClick"
      >
        <ul class="search-content" v-if="hasItems">
          <li v-for="(item, index) in items" :key="index">
            <div
              class="d-flex align-items-center m-1"
              @click="handleItemClick(item)"
            >
              <i :class="`jbsmd-${item.type}`"></i>
              <div>
                <strong>{{ item.label }}</strong>
                <span class="small" v-if="showCompanyName">
                  {{ item.metadata.company_name }}
                </span>
                <span class="small" v-if="showEmail">
                  {{ item.metadata.email }}
                </span>
              </div>
            </div>
          </li>
          <div v-observe-visibility="reachedEndOfList" v-if="hasNextPage" />
        </ul>
        <div v-else>
          <div class="no-result"><translate>No results</translate></div>
        </div>
      </div>
    </div>
  </form>
</template>
<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { BSpinner } from "bootstrap-vue";
import vClickOutside from "v-click-outside";
import { ObserveVisibility } from "vue-observe-visibility";

Vue.use(vClickOutside);

Vue.directive("observe-visibility", ObserveVisibility);

export default {
  components: { BSpinner },
  data() {
    return {
      search: "",
      show: false,
      isFetching: false,
      currentPage: 1,
      perPage: 10
    };
  },
  computed: {
    ...mapGetters("search", ["items", "hasItems", "total"]),
    hasNextPage() {
      return this.perPage * this.currentPage < this.total;
    }
  },
  methods: {
    ...mapActions("search", ["fetchSearch"]),
    fetch() {
      this.isFetching = true;
      return this.fetchSearch({
        q: this.search,
        page: this.currentPage
      }).finally(() => {
        this.isFetching = false;
      });
    },
    async submitSearch() {
      this.currentPage = 1;
      this.hideDropdown();
      await this.fetch();
      this.showDropdown();
    },
    handleItemClick(item) {
      switch (item.type) {
        case "offer":
          this.$router.push({
            name: "offer-details",
            params: { offerId: item.item_id }
          });
          break;
        case "candidate":
          this.$router.push({
            name: "candidate-details",
            params: { candidateId: item.item_id }
          });
          break;
        case "company":
          this.$router.push({
            name: "company-details",
            params: { companyId: item.item_id }
          });
          break;
        case "user":
          this.$router.push({
            name: "update-user",
            params: { id: item.item_id }
          });
          break;
      }
      this.hideDropdown();
    },
    handleInputClick() {
      if (!this.show && this.search.length) {
        this.showDropdown();
      }
    },
    handleOutsideClick(event) {
      if (event.target.id !== "search") {
        this.hideDropdown();
      }
    },
    hideDropdown() {
      this.show = false;
    },
    showDropdown() {
      this.show = true;
    },
    showCompanyName({ type }) {
      return type === "offer";
    },
    showEmail({ type }) {
      return ["candidate", "user"].includes(type);
    },
    reachedEndOfList(reached) {
      if (reached) {
        this.currentPage += 1;
        this.fetch();
      }
    }
  }
};
</script>
