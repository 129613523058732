<template>
  <section
    class="pulse-loader-container d-flex justify-content-center component-section"
  >
    <div class="text-center align-self-center">
      <pulse-loader :loading="true" color="#9176cc" size="20px"> </pulse-loader>
    </div>
  </section>
</template>

<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

export default {
  components: { PulseLoader }
};
</script>
<style>
.pulse-loader-container {
  min-height: 600px;
}
</style>
