<template>
  <!-- Navbar -->
  <nav class="navbar navbar-expand-lg fixed-top navbar-transparent">
    <div class="container-fluid">
      <div class="navbar-wrapper">
        <div
          :class="[sidebarOpen ? 'toggled' : '']"
          class="navbar-toggle"
          @click="toggleSidebar"
        >
          <button type="button" class="navbar-toggler">
            <span class="navbar-toggler-bar bar1"></span>
            <span class="navbar-toggler-bar bar2"></span>
            <span class="navbar-toggler-bar bar3"></span>
          </button>
        </div>
        <div class="logo">
          <router-link
            :to="{ name: 'dashboard' }"
            class="simple-text logo-mini"
          >
            <div class="logo-image-small">
              <img
                :src="require(`@/assets/images/enroll.svg`)"
                alt="Enroll Talent"
                height="40px"
              />
            </div>
          </router-link>
          <router-link
            :to="{ name: 'dashboard' }"
            class="simple-text logo-normal"
          >
            <img
              :src="require(`@/assets/images/enroll_logo.svg`)"
              alt="Enroll Talent"
              height="40px"
            />
          </router-link>
        </div>
        <div class="navbar-minimize">
          <button
            :disabled="!hasRoutes"
            @click="previousRoute"
            id="minimizeSidebar"
            class="btn btn-icon btn-round"
          >
            <i
              class="nc-icon nc-minimal-right text-center visible-on-sidebar-mini"
            ></i>
            <i
              class="nc-icon nc-minimal-left text-center visible-on-sidebar-regular"
            ></i>
          </button>
        </div>
      </div>
      <div class="col-8 col-lg-4">
        <SearchBar v-if="!isOrphan" />
      </div>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navigation"
        aria-controls="navigation-index"
        aria-expanded="false"
        aria-label="Toggle navigation"
        @click="toggleNavigation"
      >
        <span class="navbar-toggler-bar navbar-kebab"></span>
        <span class="navbar-toggler-bar navbar-kebab"></span>
        <span class="navbar-toggler-bar navbar-kebab"></span>
      </button>
      <div
        :class="[navigationOpen ? 'show' : '']"
        class="collapse navbar-collapse justify-content-end"
        id="navigation"
      >
        <ul class="navbar-nav">
          <li class="nav-item">
            <LanguageSwitcher @lang-switched="closeNavigation" />
          </li>
          <li class="nav-item border-left" v-if="canManageSubscriptions">
            <router-link
              class="mx-lg-2 nav-link"
              :to="{ name: 'cart' }"
              @click.native="closeNavigation"
            >
              <i class="jbsmd-cart"></i>
              <p>
                <span class="badge d-none d-lg-inline" v-if="!isEmpty">
                  {{ productsCount }}
                </span>
                <span class="d-lg-none d-md-block ml-2"
                  ><translate>My Cart</translate></span
                >
              </p>
            </router-link>
          </li>
          <li class="nav-item d-none d-lg-block">
            <UserDropdown />
          </li>
          <li v-if="!isOrphan" class="nav-item d-lg-none">
            <router-link
              :to="{ name: 'account' }"
              class="nav-link"
              @click.native="closeNavigation"
            >
              <i class="jbsmd-user"></i>
              <p>
                <span class="d-lg-none d-md-block ml-2"
                  ><translate>My profile</translate></span
                >
              </p>
            </router-link>
          </li>
          <li v-if="!isOrphan" class="nav-item d-lg-none">
            <router-link
              :to="{ name: 'teams' }"
              class="nav-link"
              @click.native="closeNavigation"
            >
              <i class="jbsmd-users"></i>
              <p>
                <span class="d-lg-none d-md-block ml-2"
                  ><translate>My teams</translate></span
                >
              </p>
            </router-link>
          </li>
          <li class="nav-item d-lg-none">
            <a
              class="nav-link"
              href="javascript:;"
              @click.prevent="handleLogout"
            >
              <i class="jbsmd-logout"></i>
              <p>
                <span class="d-lg-none d-md-block ml-2"
                  ><translate>Log Out</translate></span
                >
              </p>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <!-- End Navbar -->
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import LanguageSwitcher from "@/layouts/components/LanguageSwitcher";
import SearchBar from "@/layouts/components/SearchBar";
import UserDropdown from "@/layouts/components/UserDropdown";
import { rebootIntercom } from "@/utils/intercom";

export default {
  components: {
    UserDropdown,
    LanguageSwitcher,
    SearchBar
  },
  data() {
    return {
      sidebarOpen: false,
      navigationOpen: false
    };
  },
  computed: {
    ...mapGetters("me", ["lg", "canManageSubscriptions", "isOrphan"]),
    ...mapGetters("history", ["hasRoutes"]),
    ...mapGetters("cart", ["isEmpty", "productsCount"])
  },
  methods: {
    ...mapActions("me", ["logout"]),
    ...mapMutations("history", ["pop", "setIsGoingBack"]),
    async previousRoute() {
      await this.setIsGoingBack(true);
      if (this.hasRoutes) {
        await this.pop();
        this.$router.back();
      } else {
        await this.setIsGoingBack(false);
      }
    },
    toggleSidebar() {
      this.sidebarOpen = !this.sidebarOpen;
      document.body.classList.toggle("nav-open");
    },
    toggleNavigation() {
      this.navigationOpen = !this.navigationOpen;
    },
    closeNavigation() {
      this.navigationOpen = false;
    },
    handleLogout() {
      this.closeNavigation();
      this.logout().finally(() => {
        rebootIntercom(null, this.lg);
        this.$router.push({ name: "login" });
      });
    }
  }
};
</script>
