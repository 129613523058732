<template>
  <div :class="`wrapper ${pageClasses}`">
    <Sidebar />
    <div class="main-panel">
      <Navbar />
      <div class="content">
        <content-loader v-if="showLoader('main')" />
        <router-view v-show="!showLoader('main')" />
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import Sidebar from "@/layouts/Paper/Dashboard/Sidebar";
import Navbar from "@/layouts/Paper/Dashboard/Navbar";
import Footer from "@/layouts/Paper/Dashboard/Footer";
import ContentLoader from "@/layouts/components/ContentLoader";
import { mapGetters } from "vuex";
export default {
  components: { Footer, Sidebar, Navbar, ContentLoader },
  computed: {
    ...mapGetters("app", ["isFetching", "showLoader"]),
    ...mapGetters("subscriptions", ["hasUpdatableSubscription"]),
    pageClasses() {
      const pageClasses = [];
      if (
        this.$route.meta.showStripeIntervalBanner &&
        !this.hasUpdatableSubscription
      ) {
        pageClasses.push("has-page-banner");
      }
      return pageClasses.join(" ");
    }
  }
};
</script>
